/************************************************************************
Panel Cards
    
    Description: outer wrapper around promotional callouts
    Usage: ShareGroups

**************************************************************************/
.panel-card {
  height: unset !important;

  //Variants

  &.variant__border-top {
    .card {
      border-top: 7px solid black;
    }

    @include media-breakpoint-down(sm) {
      .card-header {
        background-color: transparent;
        border-bottom: none;
        padding-left: 25px;
        padding-bottom: 0px;
        padding-top: 40px;
      }

      .flip-card--collapse {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .panel-card {
      margin-bottom: unset;
      height: unset;

      .card {
        padding: 50px 40px 25px 40px;
        border-top: 6px solid color("black") !important;
        margin-bottom: unset;

        p {
          @include media-breakpoint-up(lg) {
            font-weight: 300;
          }
        }
      }
    }
  }
}
