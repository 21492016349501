.page-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  .page-banner_overlay-content {
    width: 50%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    h1 {
      font-weight: 300;
      @include font-size(42);
    }

    .link-stack-mobile{
      @include media-breakpoint-up(md) {
         display: flex;
         justify-content: space-around;
      }

      @include media-breakpoint-down(sm) {
         a{
          display: block;
          margin-bottom: 10px;
         }
      }
    }
  }

  .description {
    font-weight: 300;
    @include font-size(16);
    line-height: 1.44;
  }

  // Additional CSS for Page Banner Image Container so bg image remains in the back
  .image--container {
    position: absolute;
    top: 0;
  }

  /**********************************************************
   Dark Variation
  **********************************************************/
  &.dark {
    height: 450px;
    text-align: center;
    color: color("white");
    background-image: linear-gradient(to bottom, rgba(74, 74, 74, 0.95), #000000);

    .description {
      color: color("light-grey");
    }

    a {
      position: relative;

      &::after {
        content: url(/images/menu-icons/arrow-right_gold.svg);
        position: absolute;
        bottom: -3px;
        margin-left: 5px;
      }

      &:hover {
        color: color("gold");
      }
    }
  }

  /**********************************************************
   Light Variation
  **********************************************************/
  &.light {
    padding: 2.5rem 0;
    text-align: left;
    color: color("black");

    @include media-breakpoint-up(md) {
      // min-height: 140px;
    }

    @include media-breakpoint-up(lg) {
      // min-height: 170px;
    }

    // only when background image is set, add additional spacing since we set padding at the outermost level
    &.page-banner__image-set {
      margin-bottom: 30px;
    }

    .image--container {
      background-position: center;
    }

    .container-fluid {
      @include media-breakpoint-up(md) {
        padding-left: 35px;
        align-self: flex-start;
      }
    }

    .description {
      color: color("black");

      &:first-of-type {
        margin-top: 1.5rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .header-label {
      text-transform: uppercase;
      @include font-size(14);
      color: comp-color("article-label");
      margin-bottom: 1rem;
    }

    .header-title {
      margin-bottom: 1rem;
    }

    .header-sub {
      color: color("gold");
      @include font-size(15);
      font-weight: bold;
    }

    h1 {
      @include font-size(36);
      font-weight: 300;
      margin-bottom: 0;
    }
  }

  /**********************************************************
   Insights Page Variation
  **********************************************************/
  &.insights-page-banner {
    padding-top: 2rem;
    margin-bottom: 1.5rem;

    .eyebrow {
      font-weight: normal;
    }

    .header-title {
      font-size: 36px;
      margin-bottom: 5px;
    }
  }

  /**********************************************************
   Breadcrumb styling within page banners
  **********************************************************/
  nav {
    position: absolute;
    top: 0px;
    z-index: 2;
    left: 0;
    width: 100%;

    ol {
      background-color: transparent;

      .breadcrumb-item+.breadcrumb-item {
        padding-left: 0.5rem;
      }
    }
  }
}

/**********************************************************
 Overlay Variation
**********************************************************/
.page-banner_overlay {
  background: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    nav>ol {
      background-color: rgb(0, 0, 0);
      opacity: 0.4;
    }
  }

  +div {
    @extend %dark-banner-offset;
  }

  .image--container img {
    @media (min-width: 1660px) and (max-width: 1920px) {
      height: auto;
      width: 100%;
    }
  }

  .page-banner_overlay-content {
    text-align: center;
    color: color("white");
    background-image: radial-gradient(circle at 50% 6%, color("black"), #000000);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 65vw;
    padding: 30px;
    position: absolute;
    bottom: -5rem;
    border-width: 0;
    border-left: 6px solid;
    border-image: linear-gradient(to bottom, color("gold"), #e0c548 47%, color("gold")) 1 100%;

    @include media-breakpoint-down(sm) {
      width: 90%;
      bottom: -2rem;
    }

    h1 {
      margin-bottom: 0.5rem;
      color: color("white");
    }

    .call-to-action {
      margin-top: 48px;
      margin-bottom: 0px;

      @include media-breakpoint-down(sm) {
        margin-top: 24px;
      }
    }

    p {
      color: color("white");
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up(md) {
        width: 65%;
      }
    }
  }
}

/**********************************************************
 Retail Insight Center Variation
**********************************************************/
.insight-banner-row {

  .header-container {
    margin-bottom: 0rem !important;

    .label {
      text-transform: uppercase;
    }

    h1 {
      @include media-breakpoint-down(md) {
        font-size: 32px !important;
      }
    }
  }

  .actions {
    padding-bottom: 1.5rem;
    padding-left: 15px;
    padding-right: 15px;
    @include font-size(13);

    .form-check {
      display: inline-block;
    }

    .actions-checkbox-container {
      border-bottom: 1px solid color("lighter-grey");
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;

      @include media-breakpoint-down(sm) {
         margin-top: 20px;
         flex-wrap: wrap;
      }

      div:first-of-type{
        margin-bottom: 10px;
      }
    }
  }

  .insight-checkbox-row {
    padding-bottom: 0px;
  }
}

/**********************************************************
 News Detail Variation
**********************************************************/
.page-banner.news-detail {
  padding-top: 3rem !important;

  .container-fluid {

  }

  h1 {
    // margin-bottom: 1.5rem !important;
  }

  p {
    text-transform: uppercase;
    @include font-size(14);
    color: #91908f;
  }

  p.date {
    margin: 1rem 0 0;
    color: #252525;

    @include media-breakpoint-up(lg) {
      font-size: 13px;
    }
  }
}
