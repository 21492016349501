/*
*   $theme-colors: primary, success, and danger keys are used for links, buttons, and form states
*
*   sass use:       .custom-element { color: theme-color("dark"); }
*   selector use:   class="text-primary bg-dark"
*/
$theme-colors: (
  primary: #303030,
  secondary: #009999,
  success: #6caa1b,
  darkest: #000000,
  dark: #0c0c0c,
  darkish: #222222,
  light: #f1edf0,
  accent: #bd9b08
);

/*
*   $colors: Many of Bootstrap’s various components and utilities are built through a series of colors defined in a Sass map
*
*   sass use: .custom-element { color: color("purple"); }
*/
$colors: (
  "purple": #7851a9, // overriding to kriq royal-purple,,,,,,
  "warm-grey": #8b8a8a,
  "warmer-grey": #91908f,
  "dark-grey": #2c2c2c,
  "light-grey": #b8b8b8,
  "lighter-grey": #dad9d7,
  "pale-grey": #f9f8f9,
  "pale-lavendar": #edeaee,
  "white": #fff,
  "black": #303030,
  "gold": #bd9b08,
  "error": #e70000
);

@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value);
}

$component-colors: (
  "heading-strike": $body-color,
  "card-content": #252525,
  "card-tag": theme-color("darkest"),
  "article-label": #91908f,
  "footer-authenticated": #8f8e8d,
  "cta-footer-strike": #bdbdbd
);

@function comp-color($key) {
  @return map-get($component-colors, $key);
}