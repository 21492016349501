.tooltip>.tooltip-inner {
  @include font-size(12);
  min-width: 250px;

  p{
    font-size: 12px;
    margin-bottom: 10px;
  }

  p:last-of-type {
    margin-bottom: 0px;
  }

  padding: 20px 10px 20px 10px;
}

.tooltip.tooltip-dark {
  .tooltip-inner {
    max-width: 315px;

    p, span {
      color: color("white");
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}