/*
    This file is for temporary client use only to showcase some components in the SPT Shell
*/

@import "../vendor/bootstrap_override";
@import "utilities";

@import "../utilities/vars";
@import "../utilities/spacings";

@import "../components/page-banner";
@import "../components/line-split-heading";
@import "../components/cards";
@import "../components/panel-cards";
@import "../components/flip-card";