.flip-card-container {

  h2 {
    font-weight: 300;
    @include font-size(36);
    text-align: center;
  }
}

.flip-card {

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-up(lg) {
    text-align: center;
    height: 542px;
    margin-bottom: 75px;
  }

  .accordion {

    @include media-breakpoint-up(lg) {
      display: inline-block;
      white-space: normal;
      width: calc(30vw + 35px);
      min-height: 350px;
      vertical-align: top;
      margin-left: -35px;
      margin-top: 0;
      text-align: left;
    }


    &.flip {
      @include media-breakpoint-up(lg) {
        margin-left: 0px;
        margin-right: -35px;
      }
    }

    .card {
      @include media-breakpoint-up(lg) {
        height: 100%;
      }
    }
  }

  .card-header {
    @include media-breakpoint-up(lg) {
      background-color: color("white");
      border-bottom: none;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .card {
    margin-bottom: 20px;
    white-space: normal;

    @include media-breakpoint-up(lg) {
      box-shadow: 55px 90px 65px 0 rgba(20, 18, 20, 0.08);
      max-height: 450px;
      padding: 25px;
      text-align: left;
    }

    p {
      @include media-breakpoint-up(lg) {
        margin-bottom: 0px;
        font-weight: 300;
      }
    }

    .card-body {
      font-weight: 300;
      @include font-size(16);
      line-height: 1.44;
      padding: 25px !important;
    }

    .button-container{
      @include media-breakpoint-up(md) {
         margin-top: 25px;
      }
    }
  }

  &.primary {
    white-space: nowrap;

    .card{

      .card-header{
        display: none;
      }

      .card-body{
        @include media-breakpoint-down(sm) {
           padding: 0px !important
        }
      }
      h5{
        padding-left: 25px !important;
      }
      p{
        padding: 25px !important;
      }

    }
  }

  &.primary.flip {
    white-space: nowrap;
  }

  .card--image-mobile {
    width: 100%;
    margin-bottom: 25px;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .card--image-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      width: 35vw;
      display: inline-block;
      height: 542px;
    }
  }

  .flip-card--collapse {
    position: absolute;
    right: 20px;
    font-size: 2rem;
    padding: 0px;
    top: 0;
    color: color("black");

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:after {
      content: "-";
    }

    &.collapsed:after {
      content: "+";
    }
  }

  .button-container {

    .btn {
      display: block;
      width: 100%;

      &:first-of-type {
        margin-bottom: 20px;
      }

      @include media-breakpoint-up(lg) {
        display: inline-block;
        width: 60%;

        &:first-of-type {
          margin-bottom: unset;
          margin-right: 10px;
        }
      }
    }

    .btn.btn--arrow {
      min-width: unset;

      span {
        vertical-align: -webkit-baseline-middle;
      }
    }
  }

  .btn--gradient-dark {
    position: relative;
    text-align: left;
  }

  &.resources-block {
    .btn--gradient-dark {
      width: 50%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
