.heading--line-split {
  text-align: center;
  
  @include media-breakpoint-up(md) {
    display: block;
    overflow: hidden;
    white-space: nowrap;

    .inner-content {
      position: relative;
      display: inline-block;
      margin: 0;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: comp-color("heading-strike");
      }

      &:before {
        right: 100%;
        margin-right: 30px;
      }

      &:after {
        left: 100%;
        margin-left: 30px;
      }
    }
  }
}
