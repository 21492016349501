@import "variables";

/********************************************************
  Typography
  
  - 1rem font-size-base assumes the browser default of 16px

********************************************************/

$enable-responsive-font-sizes: true;

$KantarBrown: "Kantar Brown",
Helvetica,
Verdana,
sans-serif;
$KantarBrownCyrWeb: "Kantar Brown Cyr",
Helvetica,
Verdana,
sans-serif;

$font-family-base: $KantarBrownCyrWeb;
$line-height-base: 1.25; // 20px
$headings-font-weight: 400;

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5625; // 25px
$h5-font-size: $font-size-base * 1.1875; // 19px
$h6-font-size: $font-size-base;

$link-hover-decoration: none;
$link-color: color("gold");
$link-hover-color: darken(color("gold"), 5%);