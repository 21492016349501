/*
*   Override bootstrap defaults and add more sass-maps here
*
*   ref: https://getbootstrap.com/docs/4.1/getting-started/theming/
*/
$body-bg: #f1edf0;
$body-color: #303030;

$text-muted: #91908f;

/*
*   $container-max-widths: contains grid-tiers to override xs-xl max-width properties on .container
*/
$container-max-widths: (xl: 1240px);

/*******************************************************
 footer bottom
********************************************************/
%footer_bottom {
  height: inherit;
  min-height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include media-breakpoint-down(md) {
    min-height: calc(100vh - 70px);
  }
}

html:not([data-exp-editor]) {
  @extend %footer_bottom;

  body,
  .page,
  #main__outer {
    @extend %footer_bottom;
  }
}

#main__outer {
  #main__inner {
    flex-grow: 1;
    flex-shrink: 0;
  }

  #footer {
    flex-shrink: 0;
  }
}

body {
  &.public {}

  &.authenticated {
    #main__outer {
      @include media-breakpoint-up(lg) {
        padding-left: 80px;
      }
    }

    #container-content {

      // on desktop, align left 95%
      @include media-breakpoint-up(lg) {
        width: 95%;
        margin: 0 auto 0 0;
        padding-left: 35px;
      }
    }
  }
}

.detail-page-container {

  @include media-breakpoint-up(md) {
    margin: unset !important;
    width: 100% !important;
  }
}

.insights-container {

  @include media-breakpoint-up(md) {
    width: 100% !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

.news-detail-container {
  @include media-breakpoint-up(md) {
    width: 100% !important;
    padding-left: 15px !important;
  }
}

.t3-container {

  @include media-breakpoint-up(md) {
    margin: unset !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }

  .flip-card-container {

    @include media-breakpoint-up(md) {
      width: 65vw;
      margin: 0 auto;
    }
  }
}

.t11-container {
  @extend .mt-md-4;

  >div:first-of-type {
    margin-top: 0 !important;
  }
}

.detail-toolbar {
  position: relative;

  .toolbar {
    right: 0;
    top: 80px;
  }
}

[v-cloak] {
  background-image: url(/images/loader/gear-loader.svg);
  background-repeat: no-repeat;
  background-position: center;
}

[v-cloak].v-cloak--hidden{
  display: none;
}