@charset "UTF-8";
/*
    This file is for temporary client use only to showcase some components in the SPT Shell
*/
/*
*   $theme-colors: primary, success, and danger keys are used for links, buttons, and form states
*
*   sass use:       .custom-element { color: theme-color("dark"); }
*   selector use:   class="text-primary bg-dark"
*/
/*
*   $colors: Many of Bootstrap’s various components and utilities are built through a series of colors defined in a Sass map
*
*   sass use: .custom-element { color: color("purple"); }
*/
.bg-purple {
  background-color: #7851a9 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #604087 !important;
}

.bg-warm-grey {
  background-color: #8b8a8a !important;
}

a.bg-warm-grey:hover, a.bg-warm-grey:focus,
button.bg-warm-grey:hover,
button.bg-warm-grey:focus {
  background-color: #717171 !important;
}

.bg-warmer-grey {
  background-color: #91908f !important;
}

a.bg-warmer-grey:hover, a.bg-warmer-grey:focus,
button.bg-warmer-grey:hover,
button.bg-warmer-grey:focus {
  background-color: #787775 !important;
}

.bg-dark-grey {
  background-color: #2c2c2c !important;
}

a.bg-dark-grey:hover, a.bg-dark-grey:focus,
button.bg-dark-grey:hover,
button.bg-dark-grey:focus {
  background-color: #131212 !important;
}

.bg-light-grey {
  background-color: #b8b8b8 !important;
}

a.bg-light-grey:hover, a.bg-light-grey:focus,
button.bg-light-grey:hover,
button.bg-light-grey:focus {
  background-color: #9f9e9e !important;
}

.bg-lighter-grey {
  background-color: #dad9d7 !important;
}

a.bg-lighter-grey:hover, a.bg-lighter-grey:focus,
button.bg-lighter-grey:hover,
button.bg-lighter-grey:focus {
  background-color: #c1c0bd !important;
}

.bg-pale-grey {
  background-color: #f9f8f9 !important;
}

a.bg-pale-grey:hover, a.bg-pale-grey:focus,
button.bg-pale-grey:hover,
button.bg-pale-grey:focus {
  background-color: #e1dde1 !important;
}

.bg-pale-lavendar {
  background-color: #edeaee !important;
}

a.bg-pale-lavendar:hover, a.bg-pale-lavendar:focus,
button.bg-pale-lavendar:hover,
button.bg-pale-lavendar:focus {
  background-color: #d5ced7 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e5e5 !important;
}

.bg-black {
  background-color: #303030 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #171616 !important;
}

.bg-gold {
  background-color: #bd9b08 !important;
}

a.bg-gold:hover, a.bg-gold:focus,
button.bg-gold:hover,
button.bg-gold:focus {
  background-color: #8c7306 !important;
}

.bg-error {
  background-color: #e70000 !important;
}

a.bg-error:hover, a.bg-error:focus,
button.bg-error:hover,
button.bg-error:focus {
  background-color: #b40000 !important;
}

/********************************************************
  Typography
  
  - 1rem font-size-base assumes the browser default of 16px

********************************************************/
/*
*   Override bootstrap defaults and add more sass-maps here
*
*   ref: https://getbootstrap.com/docs/4.1/getting-started/theming/
*/
/*
*   $container-max-widths: contains grid-tiers to override xs-xl max-width properties on .container
*/
/*******************************************************
 footer bottom
********************************************************/
html:not([data-exp-editor]), html:not([data-exp-editor]) body,
html:not([data-exp-editor]) .page,
html:not([data-exp-editor]) #main__outer {
  height: inherit;
  min-height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media (max-width: 991.98px) {
  html:not([data-exp-editor]), html:not([data-exp-editor]) body,
  html:not([data-exp-editor]) .page,
  html:not([data-exp-editor]) #main__outer {
    min-height: calc(100vh - 70px);
  }
}

#main__outer #main__inner {
  flex-grow: 1;
  flex-shrink: 0;
}

#main__outer #footer {
  flex-shrink: 0;
}

@media (min-width: 992px) {
  body.authenticated #main__outer {
    padding-left: 80px;
  }
}

@media (min-width: 992px) {
  body.authenticated #container-content {
    width: 95%;
    margin: 0 auto 0 0;
    padding-left: 35px;
  }
}

@media (min-width: 768px) {
  .detail-page-container {
    margin: unset !important;
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .insights-container {
    width: 100% !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}

@media (min-width: 768px) {
  .news-detail-container {
    width: 100% !important;
    padding-left: 15px !important;
  }
}

@media (min-width: 768px) {
  .t3-container {
    margin: unset !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .t3-container .flip-card-container {
    width: 65vw;
    margin: 0 auto;
  }
}

.t11-container > div:first-of-type {
  margin-top: 0 !important;
}

.detail-toolbar {
  position: relative;
}

.detail-toolbar .toolbar {
  right: 0;
  top: 80px;
}

[v-cloak] {
  background-image: url(/images/loader/gear-loader.svg);
  background-repeat: no-repeat;
  background-position: center;
}

[v-cloak].v-cloak--hidden {
  display: none;
}

.tooltip > .tooltip-inner {
  font-size: 0.75rem;
  min-width: 250px;
  padding: 20px 10px 20px 10px;
}

.tooltip > .tooltip-inner p {
  font-size: 12px;
  margin-bottom: 10px;
}

.tooltip > .tooltip-inner p:last-of-type {
  margin-bottom: 0px;
}

.tooltip.tooltip-dark .tooltip-inner {
  max-width: 315px;
}

.tooltip.tooltip-dark .tooltip-inner p, .tooltip.tooltip-dark .tooltip-inner span {
  color: #fff;
}

.tooltip.tooltip-dark .tooltip-inner p:last-child, .tooltip.tooltip-dark .tooltip-inner span:last-child {
  margin-bottom: 0;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #303030 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #171616 !important;
}

.bg-secondary {
  background-color: #009999 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #006666 !important;
}

.bg-success {
  background-color: #6caa1b !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #507e14 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f1edf0 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dbd0d8 !important;
}

.bg-dark {
  background-color: #0c0c0c !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-darkest {
  background-color: #000000 !important;
}

a.bg-darkest:hover, a.bg-darkest:focus,
button.bg-darkest:hover,
button.bg-darkest:focus {
  background-color: black !important;
}

.bg-darkish {
  background-color: #222222 !important;
}

a.bg-darkish:hover, a.bg-darkish:focus,
button.bg-darkish:hover,
button.bg-darkish:focus {
  background-color: #090808 !important;
}

.bg-accent {
  background-color: #bd9b08 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #8c7306 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #303030 !important;
}

.border-secondary {
  border-color: #009999 !important;
}

.border-success {
  border-color: #6caa1b !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f1edf0 !important;
}

.border-dark {
  border-color: #0c0c0c !important;
}

.border-darkest {
  border-color: #000000 !important;
}

.border-darkish {
  border-color: #222222 !important;
}

.border-accent {
  border-color: #bd9b08 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4, .t11-container,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #303030 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0a0a0a !important;
}

.text-secondary {
  color: #009999 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #004d4d !important;
}

.text-success {
  color: #6caa1b !important;
}

a.text-success:hover, a.text-success:focus {
  color: #426811 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f1edf0 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d0c2cc !important;
}

.text-dark {
  color: #0c0c0c !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-darkest {
  color: #000000 !important;
}

a.text-darkest:hover, a.text-darkest:focus {
  color: black !important;
}

.text-darkish {
  color: #222222 !important;
}

a.text-darkish:hover, a.text-darkish:focus {
  color: black !important;
}

.text-accent {
  color: #bd9b08 !important;
}

a.text-accent:hover, a.text-accent:focus {
  color: #745f05 !important;
}

.text-body {
  color: #303030 !important;
}

.text-muted {
  color: #91908f !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.page-banner_overlay + div {
  padding-top: 10rem;
}

@media (max-width: 767.98px) {
  .page-banner_overlay + div {
    padding-top: 5rem;
  }
}

.page-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  /**********************************************************
   Dark Variation
  **********************************************************/
  /**********************************************************
   Light Variation
  **********************************************************/
  /**********************************************************
   Insights Page Variation
  **********************************************************/
  /**********************************************************
   Breadcrumb styling within page banners
  **********************************************************/
}

.page-banner .page-banner_overlay-content {
  width: 50%;
}

@media (max-width: 767.98px) {
  .page-banner .page-banner_overlay-content {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.page-banner .page-banner_overlay-content h1 {
  font-weight: 300;
  font-size: 2.625rem;
}

@media (max-width: 1200px) {
  .page-banner .page-banner_overlay-content h1 {
    font-size: calc(1.3875rem + 1.65vw) ;
  }
}

@media (min-width: 768px) {
  .page-banner .page-banner_overlay-content .link-stack-mobile {
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width: 767.98px) {
  .page-banner .page-banner_overlay-content .link-stack-mobile a {
    display: block;
    margin-bottom: 10px;
  }
}

.page-banner .description {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.44;
}

.page-banner .image--container {
  position: absolute;
  top: 0;
}

.page-banner.dark {
  height: 450px;
  text-align: center;
  color: #fff;
  background-image: linear-gradient(to bottom, rgba(74, 74, 74, 0.95), #000000);
}

.page-banner.dark .description {
  color: #b8b8b8;
}

.page-banner.dark a {
  position: relative;
}

.page-banner.dark a::after {
  content: url(/images/menu-icons/arrow-right_gold.svg);
  position: absolute;
  bottom: -3px;
  margin-left: 5px;
}

.page-banner.dark a:hover {
  color: #bd9b08;
}

.page-banner.light {
  padding: 2.5rem 0;
  text-align: left;
  color: #303030;
}

.page-banner.light.page-banner__image-set {
  margin-bottom: 30px;
}

.page-banner.light .image--container {
  background-position: center;
}

@media (min-width: 768px) {
  .page-banner.light .container-fluid {
    padding-left: 35px;
    align-self: flex-start;
  }
}

.page-banner.light .description {
  color: #303030;
}

.page-banner.light .description:first-of-type {
  margin-top: 1.5rem;
}

.page-banner.light .description:last-of-type {
  margin-bottom: 0;
}

.page-banner.light .header-label {
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #91908f;
  margin-bottom: 1rem;
}

.page-banner.light .header-title {
  margin-bottom: 1rem;
}

.page-banner.light .header-sub {
  color: #bd9b08;
  font-size: 0.9375rem;
  font-weight: bold;
}

.page-banner.light h1 {
  font-size: 2.25rem;
  font-weight: 300;
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .page-banner.light h1 {
    font-size: calc(1.35rem + 1.2vw) ;
  }
}

.page-banner.insights-page-banner {
  padding-top: 2rem;
  margin-bottom: 1.5rem;
}

.page-banner.insights-page-banner .eyebrow {
  font-weight: normal;
}

.page-banner.insights-page-banner .header-title {
  font-size: 36px;
  margin-bottom: 5px;
}

.page-banner nav {
  position: absolute;
  top: 0px;
  z-index: 2;
  left: 0;
  width: 100%;
}

.page-banner nav ol {
  background-color: transparent;
}

.page-banner nav ol .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

/**********************************************************
 Overlay Variation
**********************************************************/
.page-banner_overlay {
  background: no-repeat;
  background-size: cover;
}

@media (max-width: 767.98px) {
  .page-banner_overlay nav > ol {
    background-color: black;
    opacity: 0.4;
  }
}

@media (min-width: 1660px) and (max-width: 1920px) {
  .page-banner_overlay .image--container img {
    height: auto;
    width: 100%;
  }
}

.page-banner_overlay .page-banner_overlay-content {
  text-align: center;
  color: #fff;
  background-image: radial-gradient(circle at 50% 6%, #303030, #000000);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 65vw;
  padding: 30px;
  position: absolute;
  bottom: -5rem;
  border-width: 0;
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #bd9b08, #e0c548 47%, #bd9b08) 1 100%;
}

@media (max-width: 767.98px) {
  .page-banner_overlay .page-banner_overlay-content {
    width: 90%;
    bottom: -2rem;
  }
}

.page-banner_overlay .page-banner_overlay-content h1 {
  margin-bottom: 0.5rem;
  color: #fff;
}

.page-banner_overlay .page-banner_overlay-content .call-to-action {
  margin-top: 48px;
  margin-bottom: 0px;
}

@media (max-width: 767.98px) {
  .page-banner_overlay .page-banner_overlay-content .call-to-action {
    margin-top: 24px;
  }
}

.page-banner_overlay .page-banner_overlay-content p {
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .page-banner_overlay .page-banner_overlay-content p {
    width: 65%;
  }
}

/**********************************************************
 Retail Insight Center Variation
**********************************************************/
.insight-banner-row .header-container {
  margin-bottom: 0rem !important;
}

.insight-banner-row .header-container .label {
  text-transform: uppercase;
}

@media (max-width: 991.98px) {
  .insight-banner-row .header-container h1 {
    font-size: 32px !important;
  }
}

.insight-banner-row .actions {
  padding-bottom: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.8125rem;
}

.insight-banner-row .actions .form-check {
  display: inline-block;
}

.insight-banner-row .actions .actions-checkbox-container {
  border-bottom: 1px solid #dad9d7;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

@media (max-width: 767.98px) {
  .insight-banner-row .actions .actions-checkbox-container {
    margin-top: 20px;
    flex-wrap: wrap;
  }
}

.insight-banner-row .actions .actions-checkbox-container div:first-of-type {
  margin-bottom: 10px;
}

.insight-banner-row .insight-checkbox-row {
  padding-bottom: 0px;
}

/**********************************************************
 News Detail Variation
**********************************************************/
.page-banner.news-detail {
  padding-top: 3rem !important;
}

.page-banner.news-detail p {
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #91908f;
}

.page-banner.news-detail p.date {
  margin: 1rem 0 0;
  color: #252525;
}

@media (min-width: 992px) {
  .page-banner.news-detail p.date {
    font-size: 13px;
  }
}

.heading--line-split {
  text-align: center;
}

@media (min-width: 768px) {
  .heading--line-split {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }
  .heading--line-split .inner-content {
    position: relative;
    display: inline-block;
    margin: 0;
  }
  .heading--line-split .inner-content:before, .heading--line-split .inner-content:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #212529;
  }
  .heading--line-split .inner-content:before {
    right: 100%;
    margin-right: 30px;
  }
  .heading--line-split .inner-content:after {
    left: 100%;
    margin-left: 30px;
  }
}

.card-body {
  position: relative;
}

div.card {
  border-radius: 0;
  border: none;
  /***********************************************
    Layout
  ************************************************/
  /***********************************************
    Variations
  ************************************************/
  /***********************************************
    Add-ons
    - gold borders
    - shadows
    - hover enlarge
  ************************************************/
}

div.card p {
  color: #252525;
  font-weight: normal;
}

div.card .card-image {
  display: flex;
  position: relative;
  width: 100%;
  /******************************************************
      .scale
        description: uses 2D Positioning and Overflow hidden to place an image within a bounds without changing aspect ratio of the image but still resizing
        usage: Dashboard cards
    ******************************************************/
}

div.card .card-image.scale, div.card.card--image-default .card-image {
  overflow: hidden;
  height: 271px;
}

div.card .card-image.scale > img, div.card.card--image-default .card-image > img,
div.card .card-image.scale a > img,
div.card.card--image-default .card-image a > img {
  position: relative;
  max-width: none;
  width: auto;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

div.card .card-image.scale.feat-scale, div.card.card--image-default .card-image.feat-scale {
  height: 389px;
}

div.card .card-image.scale.scale--left img, div.card.card--image-default .card-image.scale--left img {
  left: unset;
  top: 50%;
  transform: translate(0%, -50%);
}

div.card .card-image.scale.scale--right img, div.card.card--image-default .card-image.scale--right img {
  transform: translate(0%, -50%);
  left: unset;
  float: right;
}

@media (min-width: 768px) {
  div.card .card-image.card-image--mw25 {
    max-width: 25%;
  }
}

@media (min-width: 768px) {
  div.card .card-image.card-image--mw35 {
    max-width: 35%;
  }
}

@media (min-width: 768px) {
  div.card .card-image.card-image--mw25 {
    max-width: 25%;
  }
}

@media (max-width: 767.98px) {
  div.card .card-image.xs-icon-bar {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: 10px;
    height: auto;
  }
}

@media (max-width: 767.98px) {
  div.card .card-image.xs-icon-bar img {
    position: unset;
    transform: unset;
    float: right;
    height: 30px;
    width: 30px;
  }
}

div.card .card-image a {
  display: block;
  width: 100%;
}

div.card .card-image a:hover .card-preview > span {
  color: #303030;
}

div.card .card-image a .card-preview > span {
  color: #303030;
}

div.card .card-image > img,
div.card .card-image a > img {
  width: 100%;
  z-index: 80;
}

div.card .tag {
  position: absolute;
  top: 13px;
  left: 13px;
  color: #000000;
  padding: 10px 20px;
  background-color: #fff;
  line-height: 1;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: center;
  z-index: 81;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  div.card .tag {
    max-width: 85%;
  }
}

div.card .card-body {
  display: flex;
  flex-direction: column;
  padding: 30px 32px 38px;
}

div.card .card-body a {
  font-size: 0.9375rem;
}

@media (max-width: 767.98px) {
  div.card .card-body a {
    font-size: 0.875rem;
  }
}

div.card .card-body .card--bookmark {
  top: 30px;
}

div.card .card-meta {
  min-height: 75px;
}

div.card .card-date {
  font-size: 0.875rem;
  text-transform: uppercase;
}

div.card .card-title {
  margin-bottom: 1.5rem;
  font-weight: normal;
}

@media (max-width: 767.98px) {
  div.card .card-title {
    font-size: 1.125rem;
  }
}

div.card .card-title a {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
}

div.card .card-author {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 19px;
}

@media (min-width: 992px) {
  div.card .card-location {
    font-size: 0.9375rem;
  }
}

div.card .card-description p {
  display: inline;
  font-size: 0.9375rem;
}

div.card .card-description + a.d-block {
  padding-top: 15px;
  margin-top: auto;
}

div.card .spotlight--banner {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 81;
  padding: .7rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: white;
  border-style: solid;
  border-width: 4px;
  border-image: linear-gradient(to left, #bd9b08, #e0c548 47%, #bd9b08 100%) 100% 0 100% 0/3px 0 0 stretch;
  background-color: #303030;
}

div.card .spotlight--banner > img {
  transform: unset;
  left: unset;
}

@media (min-width: 768px) {
  div.card[class*=card--horizontal-] {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  div.card[class*=card--horizontal-] .card-image {
    height: auto;
  }
  div.card[class*=card--horizontal-] .card-image.scale img, div.card[class*=card--horizontal-] div.card.card--image-default .card-image img, div.card.card--image-default div.card[class*=card--horizontal-] .card-image img {
    position: absolute;
    min-height: 100%;
  }
}

@media (min-width: 768px) {
  div.card[class*=card--horizontal-] .card-body {
    padding: 25px 25px 25px 35px;
  }
}

@media (min-width: 768px) {
  div.card.card--horizontal-right {
    flex-direction: row-reverse;
  }
}

div.card.card--featured {
  padding-left: 8px;
}

@media (min-width: 768px) {
  div.card.card--featured {
    padding-left: 4px;
  }
}

div.card.card--featured .card-body {
  padding: 37px 12px 37px 19px;
}

@media (min-width: 768px) {
  div.card.card--featured .card-body {
    padding: 35px 45px 54px;
  }
}

div.card.card--featured .card-title {
  font-weight: normal;
}

div.card.card--mini .card-image {
  height: auto;
  min-height: 99px;
}

@media (min-width: 768px) {
  div.card.card--mini .card-image {
    width: 45%;
  }
}

div.card.card--mini .card-title {
  font-weight: 600;
}

@media (min-width: 768px) {
  div.card.card--mini .card-title {
    margin-bottom: 5px;
  }
}

div.card.card--mini .tag {
  font-size: 0.6875rem;
}

@media (min-width: 768px) {
  div.card.card--mini .card-author {
    margin-bottom: 5px;
  }
}

div.card.card--mini .spotlight--banner {
  padding: .35rem 1rem .25rem 1rem;
  height: auto;
}

@media (min-width: 768px) {
  div.card.card--mini.card--horizontal-left .card-body {
    min-height: 130px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  div.card.card--mini.card--horizontal-left .card-body .read-more {
    margin-top: auto;
  }
}

div.card.card--mini, div.card.card--latest-news {
  margin-bottom: 20px;
  font-size: 0.9375rem;
}

div.card.card--mini .card-body, div.card.card--latest-news .card-body {
  padding: 15px 15px 10px 15px;
}

@media (min-width: 768px) {
  div.card.card--mini .card-body, div.card.card--latest-news .card-body {
    width: 65%;
  }
}

div.card.card--mini .card-body .card--bookmark, div.card.card--latest-news .card-body .card--bookmark {
  top: 15px;
  right: 15px;
}

div.card.card--mini .card-title, div.card.card--latest-news .card-title {
  font-size: 0.9375rem;
}

div.card.card--mini .card-meta, div.card.card--latest-news .card-meta {
  min-height: unset;
}

@media (min-width: 768px) {
  div.card.card--mini .tag, div.card.card--latest-news .tag {
    max-width: 80%;
    padding: 10px 5px;
  }
}

div.card.card--mini .read-more, div.card.card--latest-news .read-more {
  display: block;
}

@media (min-width: 768px) {
  div.card.card--latest-news .card-image {
    width: 16%;
    min-width: 105px;
  }
}

@media (min-width: 768px) {
  div.card.card--latest-news .card-image .img-fluid {
    top: 60%;
  }
}

div.card.border-gold--left:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 8px;
  background-image: linear-gradient(to bottom, #bd9b08, #ead15c 47%, #bd9b08);
}

@media (min-width: 768px) {
  div.card.border-gold--left:after {
    width: 4px;
  }
}

div.card.border-gold--top:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background-image: linear-gradient(to right, #bd9b08, #e0c548 47%, #bd9b08);
}

div.card.card--hover-enlarge {
  transition: transform .2s;
  height: 100%;
}

@media (min-width: 768px) {
  div.card.card--hover-enlarge:hover {
    transform: scale(1.1);
  }
}

div.card.card--shadow {
  box-shadow: 5px 20px 48px 0 rgba(0, 0, 0, 0.08);
}

div.card.card--shadow.card--horizontal-left, div.card.card--shadow.card--horizontal-right {
  box-shadow: 28px 45px 39px 0 rgba(0, 0, 0, 0.11);
}

@media (min-width: 768px) {
  div.card.card--image-default.card--horizontal-left .card-image > a {
    display: flex;
    flex-direction: column;
  }
}

div.card.card--image-default.card--horizontal-left .card-image img {
  max-width: 150px !important;
}

@media (min-width: 768px) {
  div.card.card--image-default.card--horizontal-left .card-image img {
    max-width: 80px !important;
    flex: 1;
  }
}

div.card.card--image-default.card--horizontal-left .card-image .spotlight--banner {
  height: 50px;
  padding: .8rem 1rem 1.5rem 1rem;
}

div.card.card--image-default.card--horizontal-left .card-image .spotlight--banner > img {
  width: auto;
  left: 0;
  top: 0;
  transform: unset;
}

div.card.card--image-default .card-image {
  background: #0c0c0c;
}

div.card.card--image-default .card-image img {
  max-width: 200px !important;
}

div.card .card-lock {
  position: absolute;
  top: 29px;
  right: 40px;
}

div.card .card-preview {
  display: block;
  position: absolute;
  bottom: 0;
  padding: 10px 15px;
  width: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
}

div.card .card-preview > span {
  display: inline-block;
  vertical-align: sub;
  margin-left: 10px;
  letter-spacing: 0.47px;
}

div.card .detail--tags_container > span:not(:last-of-type)::after {
  content: '|';
  color: #303030;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  div.card .detail--tags_container {
    display: none;
  }
}

div.card .detail--tags_container .tags__show-more > button {
  padding: 0;
  margin-left: 10px;
  font-weight: bold;
}

div.card .detail--tags_container .tags__show-more .tags__more {
  display: none;
}

div.card .detail--tags_container .tags__show-more .tags__more > span:not(:last-of-type):after {
  content: "|";
  margin-left: 10px;
  margin-right: 10px;
}

/******************************************************
  .card-list
    description: reusable cards are placed within a card list in order to control spacing. .card-list is above the ul for cases where the ul needs to have a bootstrap row
    usage: Dashboard "Recommended For You"
******************************************************/
.card-list > ul {
  padding: 0;
  list-style: none;
}

.card--bookmark {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
}

.card--bookmark:focus {
  outline: 0;
}

.card--bookmark[data-bookmarked="false"] svg > g:last-of-type {
  display: none;
}

/******************************************************
  .cards-wrapper
    description: this div wraps the card heading and either a .card-list or a single .card in order to control outer spacing and semantics
    usage: Dashboard all outer card blocks
******************************************************/
.cards-wrapper {
  margin-top: 35px;
}

.cards-wrapper .cards-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.cards-wrapper .cards-header h3 {
  font-size: 1.1875rem;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .cards-wrapper .cards-header h3 {
    flex-shrink: 1;
    padding-right: 5px;
    font-size: 1.375rem;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) and (max-width: 1200px) {
  .cards-wrapper .cards-header h3 {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.cards-wrapper .cards-header .see-all {
  flex-shrink: 0;
  font-size: 14px;
}

.cards-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.cards-header h3 {
  font-size: 1.1875rem;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .cards-header h3 {
    flex-shrink: 1;
    padding-right: 5px;
    font-size: 1.375rem;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) and (max-width: 1200px) {
  .cards-header h3 {
    font-size: calc(1.2625rem + 0.15vw) ;
  }
}

.cards-header .see-all {
  flex-shrink: 0;
  font-size: 14px;
}

/************************************************************************
Panel Cards
    
    Description: outer wrapper around promotional callouts
    Usage: ShareGroups

**************************************************************************/
.panel-card {
  height: unset !important;
}

.panel-card.variant__border-top .card {
  border-top: 7px solid black;
}

@media (max-width: 767.98px) {
  .panel-card.variant__border-top .card-header {
    background-color: transparent;
    border-bottom: none;
    padding-left: 25px;
    padding-bottom: 0px;
    padding-top: 40px;
  }
  .panel-card.variant__border-top .flip-card--collapse {
    display: none;
  }
}

@media (min-width: 992px) {
  .panel-card .panel-card {
    margin-bottom: unset;
    height: unset;
  }
  .panel-card .panel-card .card {
    padding: 50px 40px 25px 40px;
    border-top: 6px solid #303030 !important;
    margin-bottom: unset;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .panel-card .panel-card .card p {
    font-weight: 300;
  }
}

.flip-card-container h2 {
  font-weight: 300;
  font-size: 2.25rem;
  text-align: center;
}

@media (max-width: 1200px) {
  .flip-card-container h2 {
    font-size: calc(1.35rem + 1.2vw) ;
  }
}

@media (min-width: 768px) {
  .flip-card {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .flip-card {
    text-align: center;
    height: 542px;
    margin-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .flip-card .accordion {
    display: inline-block;
    white-space: normal;
    width: calc(30vw + 35px);
    min-height: 350px;
    vertical-align: top;
    margin-left: -35px;
    margin-top: 0;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .flip-card .accordion.flip {
    margin-left: 0px;
    margin-right: -35px;
  }
}

@media (min-width: 992px) {
  .flip-card .accordion .card {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .flip-card .card-header {
    background-color: #fff;
    border-bottom: none;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.flip-card .card {
  margin-bottom: 20px;
  white-space: normal;
}

@media (min-width: 992px) {
  .flip-card .card {
    box-shadow: 55px 90px 65px 0 rgba(20, 18, 20, 0.08);
    max-height: 450px;
    padding: 25px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .flip-card .card p {
    margin-bottom: 0px;
    font-weight: 300;
  }
}

.flip-card .card .card-body {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.44;
  padding: 25px !important;
}

@media (min-width: 768px) {
  .flip-card .card .button-container {
    margin-top: 25px;
  }
}

.flip-card.primary {
  white-space: nowrap;
}

.flip-card.primary .card .card-header {
  display: none;
}

@media (max-width: 767.98px) {
  .flip-card.primary .card .card-body {
    padding: 0px !important;
  }
}

.flip-card.primary .card h5 {
  padding-left: 25px !important;
}

.flip-card.primary .card p {
  padding: 25px !important;
}

.flip-card.primary.flip {
  white-space: nowrap;
}

.flip-card .card--image-mobile {
  width: 100%;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .flip-card .card--image-mobile {
    display: none;
  }
}

.flip-card .card--image-desktop {
  display: none;
}

@media (min-width: 992px) {
  .flip-card .card--image-desktop {
    width: 35vw;
    display: inline-block;
    height: 542px;
  }
}

.flip-card .flip-card--collapse {
  position: absolute;
  right: 20px;
  font-size: 2rem;
  padding: 0px;
  top: 0;
  color: #303030;
}

@media (min-width: 992px) {
  .flip-card .flip-card--collapse {
    display: none;
  }
}

.flip-card .flip-card--collapse:after {
  content: "-";
}

.flip-card .flip-card--collapse.collapsed:after {
  content: "+";
}

.flip-card .button-container .btn {
  display: block;
  width: 100%;
}

.flip-card .button-container .btn:first-of-type {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .flip-card .button-container .btn {
    display: inline-block;
    width: 60%;
  }
  .flip-card .button-container .btn:first-of-type {
    margin-bottom: unset;
    margin-right: 10px;
  }
}

.flip-card .button-container .btn.btn--arrow {
  min-width: unset;
}

.flip-card .button-container .btn.btn--arrow span {
  vertical-align: -webkit-baseline-middle;
}

.flip-card .btn--gradient-dark {
  position: relative;
  text-align: left;
}

.flip-card.resources-block .btn--gradient-dark {
  width: 50%;
}

@media (max-width: 991.98px) {
  .flip-card.resources-block .btn--gradient-dark {
    width: 100%;
  }
}
