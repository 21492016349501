.card-body {
  position: relative;
}

div.card {
  border-radius: 0;
  border: none;

  p {
    color: comp-color("card-content");
    font-weight: normal;
  }

  .card-image {
    display: flex;
    position: relative;
    width: 100%;

    /******************************************************
      .scale
        description: uses 2D Positioning and Overflow hidden to place an image within a bounds without changing aspect ratio of the image but still resizing
        usage: Dashboard cards
    ******************************************************/
    &.scale {
      overflow: hidden;
      height: 271px;

      // target only main image
      >img,
      a>img {
        position: relative;
        max-width: none;
        width: auto;          // retain images original width outside the boundary of the scale container
        min-height: 100%;     // fill vertical space of card image container
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      // [feat,*]-scale sets height boundaries
      &.feat-scale {
        height: 389px;
      }

      // scale-[left,right] sets 2D Positioning to be against either side, defaults to center
      &.scale--left {
        img {
          left: unset;
          top: 50%;
          transform: translate(0%, -50%);
        }
      }

      &.scale--right {
        img {
          transform: translate(0%, -50%);
          left: unset;
          float: right;
        }
      }
    }

    &.card-image--mw25 {
      @include media-breakpoint-up(md) {
        max-width: 25%;
      }
    }

    &.card-image--mw35 {
      @include media-breakpoint-up(md) {
        max-width: 35%;
      }
    }

    &.card-image--mw25 {
      @include media-breakpoint-up(md) {
        max-width: 25%;
      }
    }

    &.xs-icon-bar {

      @include media-breakpoint-down(sm) {
        padding-top: 11px;
        padding-bottom: 11px;
        padding-right: 10px;
        height: auto;
      }

      img {
        @include media-breakpoint-down(sm) {
          position: unset;
          transform: unset;
          float: right;
          height: 30px;
          width: 30px;
        }
      }
    }

    a {
      display: block;
      width: 100%;

      &:hover {

        // card preview on hover
        .card-preview>span {
          color: #303030;
        }
      }

      .card-preview {
        >span {
          color: #303030;
        }
      }
    }

    >img,
    a>img {
      width: 100%;
      z-index: 80;
    }
  }

  .tag {
    position: absolute;
    top: 13px;
    left: 13px;
    color: comp-color("card-tag");
    padding: 10px 20px;
    background-color: color("white");
    line-height: 1;
    text-transform: uppercase;
    @include font-size(12);
    font-weight: bold;
    text-align: center;
    z-index: 81;
    letter-spacing: 1px;

    @include media-breakpoint-up(md) {
      max-width: 85%;     // makes sure tag doesn't come too close to touching the image-container
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    padding: 30px 32px 38px;

    a {
      @include font-size(15);

      @include media-breakpoint-between(xs, sm) {
        @include font-size(14);
      }
    }

    .card--bookmark {
      top: 30px;
    }
  }

  .card-meta {
    min-height: 75px;
  }

  .card-date {
    @include font-size(14);
    text-transform: uppercase;
  }

  .card-title {
    margin-bottom: 1.5rem;
    font-weight: normal;

    @include media-breakpoint-between(xs, sm) {
      @include font-size(18);
    }

    a {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
    }
  }

  .card-author {
    @include font-size(14);
    font-weight: bold;
    margin-bottom: 19px;
  }

  .card-location {
    @include media-breakpoint-up(lg) {
      @include font-size(15);
    }
  }

  .card-description {
    p {
      display: inline;
      @include font-size(15);
    }

    // when link comes after description
      // case: description wasn't truncated, so link drops to the bottom of the card
        // additional padding-top is added to separate from description when needed
    + a.d-block {
      padding-top: 15px;
      margin-top: auto;
    }
  }

  .spotlight--banner {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 81;
    padding: .7rem;
    @include font-size(14);
    font-weight: bold;
    color: white;
    border-style: solid;
    border-width: 4px;
    border-image: linear-gradient(to left, color("gold"), #e0c548 47%, color("gold") 100%) 100% 0 100% 0/3px 0 0 stretch;
    background-color: color("black");

    >img {
      transform: unset;
      left: unset;
    }
  }

  /***********************************************
    Layout
  ************************************************/
  &[class*=card--horizontal-] {
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .card-image {
      @include media-breakpoint-up(md) {
        height: auto;

        // horizontal card being scaled
        &.scale {

          // let image height be controlled by content to the left or right
          img {
            position: absolute;
            min-height: 100%;
          }
        }
      }
    }

    .card-body {
      @include media-breakpoint-up(md) {
        padding: 25px 25px 25px 35px;
      }
    }
  }

  &.card--horizontal-right {
    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
    }
  }

  /***********************************************
    Variations
  ************************************************/
  &.card--featured {
    padding-left: 8px;

    @include media-breakpoint-up(md) {
      padding-left: 4px;
    }

    .card-body {
      padding: 37px 12px 37px 19px;

      @include media-breakpoint-up(md) {
        padding: 35px 45px 54px;
      }
    }

    .card-title {
      font-weight: normal;
    }
  }

  &.card--mini {
    .card-image {
      height: auto;
      min-height: 99px;

      @include media-breakpoint-up(md) {
        width: 45%;
      }
    }

    .card-title {
      font-weight: 600;

      @include media-breakpoint-up(md) {
        margin-bottom: 5px;
      }
    }

    .tag {
      @include font-size(11);
    }

    .card-author {
      @include media-breakpoint-up(md) {
        margin-bottom: 5px;
      }
    }

    .spotlight--banner {
      padding: .35rem 1rem .25rem 1rem;
      height: auto;
    }
  }

  &.card--mini.card--horizontal-left {
    .card-image {
      &.scale {
        // img {
        //   width: 100%;
        // }
      }
    }

    .card-body {

      // some mini cards only have a title
      @include media-breakpoint-up(md) {
        min-height: 130px;
        display: flex;
        flex-direction: column;
      }

      .read-more {
        @include media-breakpoint-up(md) {
          margin-top: auto;
        }
      }
    }
  }

  &.card--mini,
  &.card--latest-news {
    margin-bottom: 20px;
    @include font-size(15);

    .card-body {
      padding: 15px 15px 10px 15px;

      @include media-breakpoint-up(md) {
        width: 65%;
      }

      .card--bookmark {
        top: 15px;
        right: 15px;
      }
    }

    .card-title {
      @include font-size(15);
    }

    .card-meta {
      min-height: unset;
    }

    .tag {
      @include media-breakpoint-up(md) {
        max-width: 80%;
        padding: 10px 5px;
      }
    }

    .read-more {
      display: block;
    }
  }

  // latest-news cards have a smaller card-image than normal for mini-cards
  &.card--latest-news {
    .card-image {
      @include media-breakpoint-up(md) {
        width: 16%;
        min-width: 105px;
      }

      .img-fluid {
        @include media-breakpoint-up(md) {
          top: 60%;
        }
      }
    }
  }

  /***********************************************
    Add-ons
    - gold borders
    - shadows
    - hover enlarge
  ************************************************/
  &.border-gold--left:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    background-image: linear-gradient(to bottom, color("gold"), #ead15c 47%, color("gold"));

    @include media-breakpoint-up(md) {
      width: 4px;
    }
  }

  &.border-gold--top:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-image: linear-gradient(to right, color("gold"), #e0c548 47%, color("gold"));
  }

  &.card--hover-enlarge {
    transition: transform .2s;
    height: 100%;

    &:hover {
      @include media-breakpoint-up(md) {
        transform: scale(1.1);
      }
    }
  }

  &.card--shadow {
    box-shadow: 5px 20px 48px 0 rgba(0, 0, 0, 0.08);

    &.card--horizontal-left,
    &.card--horizontal-right {
      box-shadow: 28px 45px 39px 0 rgba(0, 0, 0, 0.11);
    }
  }

  // a card that uses a default content type icon
  &.card--image-default {

    // and is horizontal card
    &.card--horizontal-left {
      .card-image {
        >a {
          @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
          }
        }

        img {
          max-width: 150px !important;

          @include media-breakpoint-up(md) {
            max-width: 80px !important;
            flex: 1;
          }
        }

        .spotlight--banner {
          height: 50px;
          padding: .8rem 1rem 1.5rem 1rem;

          >img {
            width: auto;
            left: 0;
            top: 0;
            transform: unset;
          }
        }
      }
    }

    .card-image {
      background: theme-color("dark");
      @extend .scale;

      img {
        max-width: 200px !important;
      }
    }
  }

  .card-lock {
    position: absolute;
    top: 29px;
    right: 40px;
  }

  .card-preview {
    display: block;
    position: absolute;
    bottom: 0;
    padding: 10px 15px;
    width: 100%;
    left: 0;
    background: rgba(255, 255, 255, .9);

    >span {
      display: inline-block;
      vertical-align: sub;
      margin-left: 10px;
      letter-spacing: 0.47px;
    }
  }

  .detail--tags_container {

    >span:not(:last-of-type) {
      &::after {
        content: '|';
        color: #303030;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .tags__show-more {

      >button {
        padding: 0;
        margin-left: 10px;
        font-weight: bold;
      }

      .tags__more {
        display: none;

        >span:not(:last-of-type) {
          &:after {
            content: "|";
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }

  }
}

/******************************************************
  .card-list
    description: reusable cards are placed within a card list in order to control spacing. .card-list is above the ul for cases where the ul needs to have a bootstrap row
    usage: Dashboard "Recommended For You"
******************************************************/
.card-list {
  >ul {
    padding: 0;
    list-style: none;
  }
}

.card--bookmark {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;

  &:focus {
    outline: 0;
  }

  &[data-bookmarked="false"] {
    svg>g:last-of-type {
      display: none;
    }
  }
}

/******************************************************
  .cards-wrapper
    description: this div wraps the card heading and either a .card-list or a single .card in order to control outer spacing and semantics
    usage: Dashboard all outer card blocks
******************************************************/
.cards-wrapper {
  margin-top: 35px;

  .cards-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    h3 {
      @include font-size(19);

      @include media-breakpoint-between(md, lg) {
        flex-shrink: 1;
        padding-right: 5px;
        @include font-size(22);
      }
    }

    .see-all {
      flex-shrink: 0;
      font-size: 14px;
    }
  }

}

.cards-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  h3 {
    @include font-size(19);

    @include media-breakpoint-between(md, lg) {
      flex-shrink: 1;
      padding-right: 5px;
      @include font-size(22);
    }
  }

  .see-all {
    flex-shrink: 0;
    font-size: 14px;
  }
}
